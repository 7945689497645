import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import {
    GridOptions,
    RowEvent,
} from 'ag-grid-community';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IConfirmationModal } from 'src/app/shared/interfaces/modal/IConfirmationModal';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { ViewHttpService } from 'src/app/shared/services/http/view-http/view-http.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { MultiButtonGeneratorComponent } from '../../../multi-button-generator/multi-button-generator.component';
import { RequestType } from './../../../../enums/RequestType';
import { IFormGeneratorInput } from './../../../../interfaces/form-generator/IFormGeneratorInput';
import { AssociatedAssessmentsComponent } from '../../../modal-templates/views/associated-assessments/associated-assessments.component';
import { CreateEmailTemplateComponent } from '../../../modal-templates/create-email-template/create-email-template.component';

@Component({
    selector: 'app-email-templates',
    templateUrl: './email-templates.component.html',
    styleUrls: ['./email-templates.component.sass']
})
export class EmailTemplatesComponent implements OnInit {

    tableInput: ITableGeneratorInput;
    assignAssessmentFormGenInputs: IFormGeneratorInput;
    widgetRef: Widget;
    apiResponse: any;
    agGrid: GridOptions = null;
    assessmentStatus: string[] = [
        'Submitted',
        'Under Evaluation',
        'Approved',
        'Rejected'
    ];
    tableDropdownButtons: IButtonGeneratorInput[];
    actionButtonsDropdown: IButtonGeneratorInput[];
    rowData;
    issueData;
    downloadIcon: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-ellipsis-v',
        extraClass: 'action-icon'
    };
    @ViewChild('downloadMenu') downloadMenu;
    @ViewChild('buttonMenu') buttonMenu;
    modalClose: Symbol;
    macroButtonGenInput: Map<string, IButtonGeneratorInput> = new Map();
    constructor(
        widgetData: WidgetInjectedData,
        private modalService: ModalService,
        private viewHttpService: ViewHttpService,
        private cdRef: ChangeDetectorRef
    ) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.tableInput = {
            buttons: [
                {
                    buttonName: 'Add Template',
                    buttonColorType: ButtonColorType.PRIMARY,
                    buttonType: ButtonType.STROKED,
                    function: (buttonRef) => {
                        this.addEmailTemplate();
                    }
                }
            ],
            listExtraction: {
                type: 'DIRECT'
            },
            columns: [
                {
                    columnName: 'Template Name',
                    columnKey: 'templateName'
                },
                {
                    columnName: 'Template Description',
                    columnKey: 'templateDescription',
                    cellRenderer: (rowData: RowEvent) => {
                        if (!rowData.data['templateDescription']) {
                            return '-';
                        } else {
                            return rowData.data['templateDescription'];
                        }
                    }
                },
                {
                    columnName: 'Creation Date',
                    columnKey: 'creationDate',
                },
                {
                    columnName: 'Created By',
                    columnKey: 'createdBy'
                },
                {
                    columnName: 'Last Modified Date',
                    columnKey: 'lastModifiedDate'
                },
                {
                    columnName: 'Associated To',
                    columnKey: 'associations',
                    buttonGen: true,
                    componentFramework: MultiButtonGeneratorComponent,
                    valueFormatter: (rowData: RowEvent) => {
                        const itemData = rowData.data;
                        const button: IButtonGeneratorInput = {
                            buttonName: 'View',
                            buttonType: ButtonType.TEXT,
                            buttonColorType: ButtonColorType.SUCCESS,
                            hoverText: itemData['associations'] &&
                                itemData['associations'].length > 0
                                    ? ''
                                    : 'No Associated Assessments',
                            disable: itemData['associations'] &&
                                itemData['associations'].length > 0
                                    ? false
                                    : true,
                            function: () => {
                                const modalData: IModalData = {
                                    modalName: 'Associated Assessments',
                                    modalHeightVh: 90,
                                    modalWidthVw: 55,
                                    modalIcon: null,
                                    modalType: ModalType.MIDDLE,
                                    sourceId:
                                        this.widgetRef.widgetData
                                            .widgetUniqueIdentifier,
                                    modalSteps: [
                                        {
                                            stepName: 'Associated Assessments',
                                            stepData: {
                                                componentToLoad:
                                                    AssociatedAssessmentsComponent,
                                                payload: {
                                                    data: {
                                                        itemData
                                                    }
                                                }
                                            }
                                        }
                                    ]
                                };
                                this.modalService.openModal(modalData);
                            }
                        };
                        rowData['buttonGenInputs'] = [button];
                        return rowData;
                    }
                },
                {
                    columnName: 'Action',
                    columnKey: 'action',
                    pinned: 'right',
                    minWidth: 360,
                    buttonGen: true,
                    cellClass: 'grid-cell-data-centred',
                    componentFramework: MultiButtonGeneratorComponent,
                    valueFormatter: (rowData) => {
                        this.issueData = rowData.data;
                        const editable = rowData.data['canEdit'] ? true : false;
                        const canDelete = rowData.data['canDelete'] ? true : false;
                        const buttons: IButtonGeneratorInput[] = [
                            {
                                buttonName: 'Edit',
                                buttonType: ButtonType.TEXT,
                                buttonColorType: ButtonColorType.INFO,
                                showLoader: true,
                                disable: !editable,
                                customClass: editable ? '' : 'decrease-opacity',
                                function: (
                                    buttonRef: IButtonGeneratorInput
                                ) => {
                                    // // Edit Assessment
                                    buttonRef.loader = true;
                                    const apiArgs = Helper.generateHitApiConfig(
                                        this.widgetRef.widgetData.widgetInfo['get']
                                    );
                                    apiArgs.url = apiArgs.url.replace(
                                        '{email_template_id}',
                                        rowData.data['id']
                                    );
                                    apiArgs.input = {};
                                    apiArgs.function = (response) => {
                                        buttonRef.loader = false;
                                        this.editEmailTemplate(response);
                                        this.widgetRef.changeDetectorRef.detectChanges();
                                    };
                                    apiArgs.errorFunction = (error) => {
                                        buttonRef.loader = false;
                                        Helper.showErrorMessage(
                                            this.widgetRef.notificationsService,
                                            error
                                        );
                                        this.widgetRef.changeDetectorRef.detectChanges();
                                    };
                                    new HitApi(
                                        apiArgs,
                                        this.viewHttpService,
                                        this.viewHttpService.ngZone
                                    ).hitApi();
                                }
                            },
                            {
                                buttonName: 'View',
                                showLoader: true,
                                buttonType: ButtonType.TEXT,
                                buttonColorType: ButtonColorType.SUCCESS,
                                function: (
                                    buttonRef: IButtonGeneratorInput
                                ) => {
                                    // View Assessment
                                    buttonRef.loader = true;
                                    const apiArgs = Helper.generateHitApiConfig(
                                        this.widgetRef.widgetData.widgetInfo['get']
                                    );
                                    apiArgs.intactUrl = apiArgs.url;
                                    apiArgs.url = apiArgs.url.replace(
                                        '{email_template_id}',
                                        rowData.data['id']
                                    );
                                    apiArgs.requestType = RequestType.GET;
                                    apiArgs.input = {};
                                    apiArgs.function = (response) => {
                                        buttonRef.loader = false;
                                        this.viewEmailTemplate(response);
                                        this.widgetRef.changeDetectorRef.detectChanges();
                                    };
                                    apiArgs.errorFunction = (error) => {
                                        buttonRef.loader = false;
                                        Helper.showErrorMessage(
                                            this.widgetRef.notificationsService,
                                            error
                                        );
                                        this.widgetRef.changeDetectorRef.detectChanges();
                                    };
                                    new HitApi(
                                        apiArgs,
                                        this.viewHttpService,
                                        this.viewHttpService.ngZone
                                    ).hitApi();
                                }

                            },
                            {
                                buttonName: 'Delete',
                                showLoader: true,
                                buttonType: ButtonType.TEXT,
                                buttonColorType: ButtonColorType.WARN,
                                disable: !canDelete,
                                customClass: canDelete ? '' : 'decrease-opacity',
                                function: (
                                    buttonRef: IButtonGeneratorInput
                                ) => {
                                    const modalData: IConfirmationModal = {
                                        modalName: 'Delete Email Template',
                                        confirmationMessage:
                                            'Please note that once you click "Confirm", this Email Template & the rules associated with the email template will be deleted.',
                                        buttonText: 'Confirm',
                                        buttonColorType: ButtonColorType.PRIMARY,
                                        hideCancelButton: true,
                                        loaderOnExec: true,
                                        function: (modalId: Symbol) => {
                                            this.deleteEmailTemplate(buttonRef, modalId, rowData);
                                            this.cdRef.detectChanges();
                                        },
                                        modalWidthVw: 40,
                                        modalHeightVh: 30,
                                    };
                                    this.modalClose =
                                        this.modalService.openConfirmationModal(modalData);
                                }

                            },
                            {
                                buttonName: 'Duplicate',
                                showLoader: true,
                                buttonType: ButtonType.TEXT,
                                buttonColorType: ButtonColorType.INFO,
                                function: (
                                    buttonRef: IButtonGeneratorInput
                                ) => {

                                    // Edit Assessment
                                    buttonRef.loader = true;
                                    const apiArgs = Helper.generateHitApiConfig(
                                        this.widgetRef.widgetData.widgetInfo['get']
                                    );
                                    apiArgs.url = apiArgs.url.replace(
                                        '{email_template_id}',
                                        rowData.data['id']
                                    );
                                    apiArgs.requestType = RequestType.GET;
                                    apiArgs.input = {};
                                    apiArgs.function = (response) => {
                                        buttonRef.loader = false;
                                        this.duplicateEmailTemplate(response);
                                        this.widgetRef.changeDetectorRef.detectChanges();
                                    };
                                    apiArgs.errorFunction = (error) => {
                                        buttonRef.loader = false;
                                        Helper.showErrorMessage(
                                            this.widgetRef.notificationsService,
                                            error
                                        );
                                        this.widgetRef.changeDetectorRef.detectChanges();
                                    };
                                    new HitApi(
                                        apiArgs,
                                        this.viewHttpService,
                                        this.viewHttpService.ngZone
                                    ).hitApi();
                                }
                            }
                        ]
                        rowData['buttonGenInputs'] = buttons;
                        return rowData;
                    }
                }
            ]
        };
    }

    addEmailTemplate() {
        const modalData: IModalData = {
            modalName: 'Create Email',
            modalIcon: null,
            sourceId: Symbol(),
            modalType: ModalType.MIDDLE,
            modalHeightVh: 96,
            modalWidthVw: 96,
            noStepPadding: true,
            resetModal: true,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: CreateEmailTemplateComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                            }
                        }
                    },
                    stepName: 'Step 1'
                }
            ]
        };
        this.modalService.openModal(modalData);
    }

    editEmailTemplate(response) {
        const modalData: IModalData = {
            modalName: 'Edit Email Template',
            modalIcon: null,
            sourceId: Symbol(),
            modalType: ModalType.MIDDLE,
            modalHeightVh: 96,
            modalWidthVw: 96,
            noStepPadding: true,
            resetModal: true,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: CreateEmailTemplateComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                isEdit: true,
                                stepData: response
                            }
                        }
                    },
                    stepName: 'Step 1'
                }
            ]
        };
        this.modalService.openModal(modalData);
    }

    viewEmailTemplate(response) {
        const modalData: IModalData = {
            modalName: 'E-mail Template',
            modalIcon: null,
            sourceId: Symbol(),
            modalType: ModalType.MIDDLE,
            modalHeightVh: 96,
            modalWidthVw: 96,
            noStepPadding: true,
            resetModal: true,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: CreateEmailTemplateComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                isView: true,
                                stepData: response
                            }
                        }
                    },
                    stepName: 'Step 1'
                }
            ]
        };
        this.modalService.openModal(modalData);
    }

    duplicateEmailTemplate(response) {
        const modalData: IModalData = {
            modalName: 'Duplicate Email Template',
            modalIcon: null,
            sourceId: Symbol(),
            modalType: ModalType.MIDDLE,
            modalHeightVh: 96,
            modalWidthVw: 96,
            noStepPadding: true,
            resetModal: true,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: CreateEmailTemplateComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                isDuplicate: true,
                                stepData: response
                            }
                        }
                    },
                    stepName: 'Step 1'
                }
            ]
        };
        this.modalService.openModal(modalData);
    }


    deleteEmailTemplate(buttonRef: IButtonGeneratorInput, modalId: Symbol, rowData) {
        const deleteArgs =
            Helper.generateHitApiConfig(
                this.widgetRef.widgetData.widgetInfo
                    .delete
            );
        deleteArgs.url = deleteArgs.url.replace(
            '{email_template_id}',
            rowData['data']['id']
        );
        deleteArgs.input = {};
        deleteArgs.function = () => {
            this.widgetRef.notificationsService.showSnackBar(
                'Email Template Deleted successfully'
            );
            this.widgetRef.modalService.closeModal(
                null,
                modalId
            );
            this.widgetRef.refreshWidget();
        };
        deleteArgs.errorFunction = (error) => {
            this.widgetRef.notificationsService.showSnackBar(
                error && error.error.message
                    ? error.error.message
                    : 'Error deleting email template',
                true
            );
            this.widgetRef.modalService.closeModal(
                null,
                modalId
            );
        };
        new HitApi(
            deleteArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    onGridReady() {
        if (this.agGrid && this.apiResponse && this.apiResponse.length) {
            const index = -1;
            if (index > 0) {
                const assessmentData = this.apiResponse[index];
                this.apiResponse.splice(index, 1);
                this.apiResponse.unshift(assessmentData);
                this.agGrid.api.setRowData(this.apiResponse);
                this.agGrid.api.refreshCells();
            }
        }
    }
}
