import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { NgxSummernoteModule } from 'ngx-summernote';
import { NgSelectModule } from '@ng-select/ng-select';
import { AgGridCellColumnComponent } from '../../components/ag-grid-cell-column/ag-grid-cell-column.component';
import { AttentionRequiredAccordionComponent } from '../../components/attention-required-accordion/attention-required-accordion.component';
import { CustomWhitelistingModalComponent } from '../../components/custom-whitelisting-modal/custom-whitelisting-modal.component';
import { KeyValuePairComponent } from '../../components/key-value-pair/key-value-pair.component';
import { AwsOnboardingModalComponent } from '../../components/modal-templates/account-onboarding-modals/aws-onboarding-modal/aws-onboarding-modal.component';
import { AzureSponsorshipOnboardingModalComponent } from '../../components/modal-templates/account-onboarding-modals/azure-sponsorship-onboarding-modal/azure-sponsorship-onboarding-modal.component';
import { CspOnboardingModalComponent } from '../../components/modal-templates/account-onboarding-modals/csp-onboarding-modal/csp-onboarding-modal.component';
import { EaOnboardingModalComponent } from '../../components/modal-templates/account-onboarding-modals/ea-onboarding-modal/ea-onboarding-modal.component';
import { GcpOnboardingModalComponent } from '../../components/modal-templates/account-onboarding-modals/gcp-onboarding-modal/gcp-onboarding-modal.component';
import { McaOnboardingModalComponent } from '../../components/modal-templates/account-onboarding-modals/mca-onboarding-modal/mca-onboarding-modal.component';
import { PlanOnboardingModalComponent } from '../../components/modal-templates/account-onboarding-modals/plan-onboarding-modal/plan-onboarding-modal.component';
import { AclAssociatedListingComponent } from '../../components/modal-templates/acl-modals/acl-associated-listing/acl-associated-listing.component';
import { AclModalStepOneComponent } from '../../components/modal-templates/acl-modals/acl-modal-step-one/acl-modal-step-one.component';
import { AclModalStepTwoComponent } from '../../components/modal-templates/acl-modals/acl-modal-step-two/acl-modal-step-two.component';
import { AddWidgetComponent } from '../../components/modal-templates/add-widget/add-widget.component';
import { AdvancedAutomationModalCongifurationComponent } from '../../components/modal-templates/advanced-automation-modal-steps/advanced-automation-modal-congifuration/advanced-automation-modal-congifuration.component';
import { AdvancedAutomationModalFiltersComponent } from '../../components/modal-templates/advanced-automation-modal-steps/advanced-automation-modal-filters/advanced-automation-modal-filters.component';
import { AdvancedAutomationModalSchedulerComponent } from '../../components/modal-templates/advanced-automation-modal-steps/advanced-automation-modal-scheduler/advanced-automation-modal-scheduler.component';
import { AddResourceTagsV2Component } from '../../components/modal-templates/allocation-checks-modal-v2/add-resource-tags-v2/add-resource-tags-v2.component';
import { ConventionsModalV2Component } from '../../components/modal-templates/allocation-checks-modal-v2/conventions-modal-v2/conventions-modal-v2.component';
import { DeleteResourceTagsV2Component } from '../../components/modal-templates/allocation-checks-modal-v2/delete-resource-tags-v2/delete-resource-tags-v2.component';
import { AddResourceTagsComponent } from '../../components/modal-templates/allocation-checks-modal/add-resource-tags/add-resource-tags.component';
import { ConventionsModalComponent } from '../../components/modal-templates/allocation-checks-modal/conventions-modal/conventions-modal.component';
import { DeleteResourceTagsComponent } from '../../components/modal-templates/allocation-checks-modal/delete-resource-tags/delete-resource-tags.component';
import { AssessmentMoreInfoModalComponent } from '../../components/modal-templates/assessee-view/assessment-more-info-modal/assessment-more-info-modal.component';
import { AssessmentCreateUserStepOneComponent } from '../../components/modal-templates/assessee-view/assessment-user/assessment-create-user-step-one/assessment-create-user-step-one.component';
import { AssessmentCreateUserStepTwoComponent } from '../../components/modal-templates/assessee-view/assessment-user/assessment-create-user-step-two/assessment-create-user-step-two.component';
import { UploadEvidenceModalComponent } from '../../components/modal-templates/assessee-view/upload-evidence-modal/upload-evidence-modal.component';
import { AssignAssessmentComponent } from '../../components/modal-templates/assign-assessment/assign-assessment.component';
import { AutomationModalConfigureNotificationComponent } from '../../components/modal-templates/automation-modal-steps/automation-modal-configure-notification/automation-modal-configure-notification.component';
import { AutomationModalFiltersComponent } from '../../components/modal-templates/automation-modal-steps/automation-modal-filters/automation-modal-filters.component';
import { AutomationModalSchedulerComponent } from '../../components/modal-templates/automation-modal-steps/automation-modal-scheduler/automation-modal-scheduler.component';
import { BulkUploadAzureEaComponent } from '../../components/modal-templates/azure-ea-onboarding-modal/bulk-upload-azure-ea/bulk-upload-azure-ea.component';
import { BulkUploadAzurePlanAccountComponent } from '../../components/modal-templates/azure-plan-onboarding-modal-steps/bulk-upload-azure-plan-account/bulk-upload-azure-plan-account.component';
import { AzureRiRecommendationModalV2Component } from '../../components/modal-templates/azure-ri-recommendation-modal-v2/azure-ri-recommendation-modal-v2.component';
import { AzureRiRecommendationModalComponent } from '../../components/modal-templates/azure-ri-recommendation-modal/azure-ri-recommendation-modal/azure-ri-recommendation-modal.component';
import { AzureSponsorshipOnboardingImpactModalComponent } from '../../components/modal-templates/azure-sponsorship-onboarding-impact-modal/azure-sponsorship-onboarding-impact-modal.component';
import { AddAzureSponsorshipAccountComponent } from '../../components/modal-templates/azure-sponsorship-onboarding-modal-steps/add-azure-sponsorship-account/add-azure-sponsorship-account.component';
import { BudgetingModalStep1Component } from '../../components/modal-templates/budgeting-modal-steps/budgeting-modal-step1/budgeting-modal-step1.component';
import { BudgetingModalStep2Component } from '../../components/modal-templates/budgeting-modal-steps/budgeting-modal-step2/budgeting-modal-step2.component';
import { BudgetingModalStep3Component } from '../../components/modal-templates/budgeting-modal-steps/budgeting-modal-step3/budgeting-modal-step3.component';
import { CartComponent } from '../../components/modal-templates/cart/cart.component';
import { ChecksV2MoreInfoModalComponent } from '../../components/modal-templates/checks-v2-more-info-modal/checks-v2-more-info-modal.component';
import { ComplianceInfoModalComponent } from '../../components/modal-templates/checks-v2/compliance-info-modal/compliance-info-modal.component';
import { ComplianceSeverityInfoModalComponent } from '../../components/modal-templates/checks-v2/compliance-severity-info-modal/compliance-severity-info-modal.component';
import { CustomizationModalComponent } from '../../components/modal-templates/checks-v2/customization-modal/customization-modal.component';
import { CommonMoreInfoModalComponent } from '../../components/modal-templates/common-more-info-modal/common-more-info-modal.component';
import { ConfigureMfaComponent } from '../../components/modal-templates/configure-mfa/configure-mfa.component';
import { ConfirmationModalComponent } from '../../components/modal-templates/confirmation-modal/confirmation-modal.component';
import { CpuUtilizationEditModalComponent } from '../../components/modal-templates/cpu-utilization-modals/cpu-utilization-edit-modal/cpu-utilization-edit-modal.component';
import { CpuUtilizationModalComponent } from '../../components/modal-templates/cpu-utilization-modals/cpu-utilization-modal/cpu-utilization-modal.component';
import { CreateAssessmentComponent } from '../../components/modal-templates/create-assessment/create-assessment.component';
import { CreateCustomerStepOneV2Component } from '../../components/modal-templates/create-customer/create-customer-step-one-v2/create-customer-step-one-v2.component';
import { CreateCustomerStepOneComponent } from '../../components/modal-templates/create-customer/create-customer-step-one/create-customer-step-one.component';
import { CreateCustomerStepThreeComponent } from '../../components/modal-templates/create-customer/create-customer-step-three/create-customer-step-three.component';
import { CreateCustomerStepTwoComponent } from '../../components/modal-templates/create-customer/create-customer-step-two/create-customer-step-two.component';
import { CustomerInfoModalComponent } from '../../components/modal-templates/create-customer/customer-info-modal/customer-info-modal.component';
import { CreateTagConfigurationFormStepOne } from '../../components/modal-templates/create-tag-configuration-Form/create-tag-ConfigurationForm-step-one/create-tag-ConfigurationForm-step-one';
import { CreateTagConfigurationFormStepTwo } from '../../components/modal-templates/create-tag-configuration-Form/create-tag-configurationForm-step-two/create-tag-ConfigurationForm-step-two';
import { CreateUserStepOneComponent } from '../../components/modal-templates/create-user/create-user-step-one/create-user-step-one.component';
import { CreateUserStepTwoComponent } from '../../components/modal-templates/create-user/create-user-step-two/create-user-step-two/create-user-step-two.component';
import { CreateWidgetStepOneComponent } from '../../components/modal-templates/create-widget/create-widget-step-one/create-widget-step-one.component';
import { CreateWidgetStepTwoComponent } from '../../components/modal-templates/create-widget/create-widget-step-two/create-widget-step-two.component';
import { CreateCustomComplianceStepOneComponent } from '../../components/modal-templates/custom-compliance-overview/create-custom-compliance-step-one/create-custom-compliance-step-one.component';
import { CreateCustomComplianceStepThreeComponent } from '../../components/modal-templates/custom-compliance-overview/create-custom-compliance-step-three/create-custom-compliance-step-three.component';
import { CustomComplianceWidgetListComponent } from '../../components/modal-templates/custom-compliance-overview/custom-compliance-widget-list/custom-compliance-widget-list.component';
import { DeleteComponent } from '../../components/modal-templates/delete/delete.component';
import { EmailTestingModalComponent } from '../../components/modal-templates/email-testing-modal/email-testing-modal.component';
import { ExpandableTableMoreInfoModalComponent } from '../../components/modal-templates/expandable-table/expandable-table-more-info-modal/expandable-table-more-info-modal.component';
import { FormGeneratorModalComponent } from '../../components/modal-templates/form-generator-modal/form-generator-modal.component';
import { GetFullAccessComponent } from '../../components/modal-templates/get-full-access/get-full-access.component';
import { IframeOpenerModalComponent } from '../../components/modal-templates/iframe-opener-modal/iframe-opener-modal.component';
import { ImageUploadModalComponent } from '../../components/modal-templates/image-upload-modal/image-upload-modal.component';
import { InfoModalComponent } from '../../components/modal-templates/info-modal/info-modal.component';
import { AutotaskChildIntegrationComponent } from '../../components/modal-templates/integration/autotask-modal/autotask-child-integration/autotask-child-integration.component';
import { AutotaskCreateTicketModalComponent } from '../../components/modal-templates/integration/autotask-modal/autotask-create-ticket-modal/autotask-create-ticket-modal.component';
import { AutotaskIntegrationsTableComponent } from '../../components/modal-templates/integration/autotask-modal/autotask-integrations-table/autotask-integrations-table.component';
import { AutotaskParentIntegrationStepOneComponent } from '../../components/modal-templates/integration/autotask-modal/autotask-parent-integration-step-one/autotask-parent-integration-step-one.component';
import { AutotaskParentIntegrationStepTwoComponent } from '../../components/modal-templates/integration/autotask-modal/autotask-parent-integration-step-two/autotask-parent-integration-step-two.component';
import { CaChildIntegrationComponent } from '../../components/modal-templates/integration/ca-sdm-modal/ca-sdm-child-level-integration/ca-child-integration/ca-child-integration.component';
import { CaCreateTicketModalComponent } from '../../components/modal-templates/integration/ca-sdm-modal/ca-sdm-create-ticket-modal/ca-create-ticket-modal/ca-create-ticket-modal.component';
import { CaIntegrationTableComponent } from '../../components/modal-templates/integration/ca-sdm-modal/ca-sdm-integration-table/ca-integration-table/ca-integration-table.component';
import { CaIntegrationStepOneComponent } from '../../components/modal-templates/integration/ca-sdm-modal/ca-sdm-parent-Integration-step-one/ca-integration-step-one/ca-integration-step-one.component';
import { CaIntegrationStepTwoComponent } from '../../components/modal-templates/integration/ca-sdm-modal/ca-sdm-parent-integration-step-two/ca-integration-step-two/ca-integration-step-two.component';
import { NagiosGroupEditModalComponent } from '../../components/modal-templates/integration/nagios-modal/nagios-group-edit-modal/nagios-group-edit-modal.component';
import { NagiosIntegrationStepOneComponent } from '../../components/modal-templates/integration/nagios-modal/nagios-integration-step-one/nagios-integration-step-one.component';
import { NagiosIntegrationStepTwoComponent } from '../../components/modal-templates/integration/nagios-modal/nagios-integration-step-two/nagios-integration-step-two.component';
import { OtrsChildIntegrationComponent } from '../../components/modal-templates/integration/otrs-modal/otrs-child-integration/otrs-child-integration.component';
import { OtrsCreateTicketModalComponent } from '../../components/modal-templates/integration/otrs-modal/otrs-create-ticket-modal/otrs-create-ticket-modal.component';
import { OtrsIntegrationsTableComponent } from '../../components/modal-templates/integration/otrs-modal/otrs-integrations-table/otrs-integrations-table.component';
import { OtrsParentIntegrationStepOneComponent } from '../../components/modal-templates/integration/otrs-modal/otrs-parent-integration-step-one/otrs-parent-integration-step-one.component';
import { OtrsParentIntegrationStepTwoComponent } from '../../components/modal-templates/integration/otrs-modal/otrs-parent-integration-step-two/otrs-parent-integration-step-two.component';
import { SalesforceIntegrationStepTwoComponent } from '../../components/modal-templates/integration/salesforce-modal/salesforce-integration-step-two/salesforce-integration-step-two.component';
import { SalesforceIntegrationStepOneComponent } from '../../components/modal-templates/integration/salesforce-modal/salesforce-integrations-step-one/salesforce-integration-step-one.component';
import { SalesforceIntegrationsTableComponent } from '../../components/modal-templates/integration/salesforce-modal/salesforce-integrations-table/salesforce-integrations-table.component';
import { ServicenowTestingModalComponent } from '../../components/modal-templates/integration/servicenow-testing-modal/servicenow-testing-modal.component';
import { SlackTestingModalComponent } from '../../components/modal-templates/integration/slack-testing-modal/slack-testing-modal.component';
import { ZendeskCreateTicketComponent } from '../../components/modal-templates/integration/zendesk-modal/zendesk-create-ticket/zendesk-create-ticket.component';
import { IssueAssessmentComponent } from '../../components/modal-templates/issue-assessment/issue-assessment/issue-assessment.component';
import { CreateMgReportStepOneComponent } from '../../components/modal-templates/managed-report-listing-modal/create-mg-report-step-one/create-mg-report-step-one.component';
import { CreateMgReportStepThreeComponent } from '../../components/modal-templates/managed-report-listing-modal/create-mg-report-step-three/create-mg-report-step-three.component';
import { CreateMgReportStepTwoComponent } from '../../components/modal-templates/managed-report-listing-modal/create-mg-report-step-two/create-mg-report-step-two.component';
import { MasterLoginUsersListComponent } from '../../components/modal-templates/master-login/master-login-users-list/master-login-users-list.component';
import { McaOnboardingBulkUploadComponent } from '../../components/modal-templates/mca-onboarding-bulk-upload/mca-onboarding-bulk-upload.component';
import { MoreInfoModalComponent } from '../../components/modal-templates/more-info-modal/more-info-modal/more-info-modal.component';
import { MyPriceConfigurationModalComponent } from '../../components/modal-templates/my-price-configuration/my-price-config-modal/my-price-configuration-modal/my-price-configuration-modal.component';
import { OtpVerificationModalComponent } from '../../components/modal-templates/otp-verification-modal/otp-verification-modal/otp-verification-modal.component';
import { RemediateDocumentationModalComponent } from '../../components/modal-templates/remediate-documentation-modal/remediate-documentation-modal.component';
import { CreateReportFirstStepComponent } from '../../components/modal-templates/report-listing-modal/create-report-first-step/create-report-first-step.component';
import { CreateReportSecondStepComponent } from '../../components/modal-templates/report-listing-modal/create-report-second-step/create-report-second-step.component';
import { CreateReportThirdStepComponent } from '../../components/modal-templates/report-listing-modal/create-report-third-step/create-report-third-step.component';
import { RequestDetailsModalComponent } from '../../components/modal-templates/request-details-modal/request-details-modal.component';
import { RiPurchaseFormComponent } from '../../components/modal-templates/ri-purchase-form/ri-purchase-form.component';
import { RiRecommendationModalComponent } from '../../components/modal-templates/ri-recommendation-modal/ri-utilization-modal/ri-recommendation-modal.component';
import { RiUtilizationModalV2Component } from '../../components/modal-templates/ri-utilization-modal-v2/ri-utilization-modal-v2.component';
import { RightsizingModalTableComponent } from '../../components/modal-templates/rightsizing/rightsizing-modal-table/rightsizing-modal-table.component';
import { RightsizingModalComponent } from '../../components/modal-templates/rightsizing/rightsizing-modal/rightsizing-modal.component';
import { SavingsPlanRecommendationModalV2Component } from '../../components/modal-templates/savings-plan-recommendation-modal-v2/savings-plan-recommendation-modal-v2.component';
import { SavingsPlanRecommendationModalComponent } from '../../components/modal-templates/savings-plan-recommendation-modal/savings-plan-recommendation-modal.component';
import { ScoringAssessmentModalComponent } from '../../components/modal-templates/scoring-assessment-modal/scoring-assessment-modal.component';
import { ServicenowTicketInformationComponent } from '../../components/modal-templates/servicenow/servicenow-ticket-information/servicenow-ticket-information.component';
import { SnsModalComponent } from '../../components/modal-templates/sns-modal/sns-modal/sns-modal.component';
import { TableGeneratorModalComponent } from '../../components/modal-templates/table-generator-modal/table-generator-modal.component';
import { AddTagConfigFirstStepComponent } from '../../components/modal-templates/tag-config-modal/add-tag-config-first-step/add-tag-config-first-step.component';
import { PreviewTfFileComponent } from '../../components/modal-templates/terraform/preview-tf-file/preview-tf-file.component';
import { TerraformMapVariablesModalComponent } from '../../components/modal-templates/terraform/terraform-map-variables-modal/terraform-map-variables-modal.component';
import { TerraformMoreInfoModalComponent } from '../../components/modal-templates/terraform/terraform-more-info-modal/terraform-more-info-modal.component';
import { TerraformPreviewResourcesModalComponent } from '../../components/modal-templates/terraform/terraform-preview-resources-modal/terraform-preview-resources-modal.component';
import { TerraformRequestFormModalComponent } from '../../components/modal-templates/terraform/terraform-request-form-modal/terraform-request-form-modal.component';
import { TerraformRequestWidgetFormModalComponent } from '../../components/modal-templates/terraform/terraform-request-widget-form-modal/terraform-request-widget-form-modal.component';
import { TerraformRequestWidgetModalComponent } from '../../components/modal-templates/terraform/terraform-request-widget-modal/terraform-request-widget-modal.component';
import { UploadTfFilesModalComponent } from '../../components/modal-templates/terraform/upload-tf-files-modal/upload-tf-files-modal.component';
import { UploadJsonComponent } from '../../components/modal-templates/upload-json/upload-json.component';
import { AssociatedAssessmentsComponent } from '../../components/modal-templates/views/associated-assessments/associated-assessments.component';
import { AssociatedUsersComponent } from '../../components/modal-templates/views/associated-users/associated-users.component';
import { CreateDuplicateViewComponent } from '../../components/modal-templates/views/create-duplicate-view/create-duplicate-view.component';
import { CreateViewComponent } from '../../components/modal-templates/views/create-view/create-view.component';
import { EditModuleIconComponent } from '../../components/modal-templates/views/edit-module-icon/edit-module-icon.component';
import { EditViewDataComponent } from '../../components/modal-templates/views/edit-view-data/edit-view-data.component';
import { GroupWidgetModalComponent } from '../../components/modal-templates/views/group-widget-modal/group-widget-modal.component';
import { LandingPageSelectionComponent } from '../../components/modal-templates/views/landing-page-selection/landing-page-selection.component';
import { ModuleWidgetAdditionComponent } from '../../components/modal-templates/views/module-widget-addition/module-widget-addition.component';
import { WidgetCatalogMoreInfoModalComponent } from '../../components/modal-templates/widget-catalog-more-info-modal/widget-catalog-more-info-modal.component';
import { WidgetCatalogTagsModalComponent } from '../../components/modal-templates/widget-catalog-tags-modal/widget-catalog-tags-modal.component';
import { FormBuilderModalComponent } from '../../components/modal-templates/widget-creation-modal/form-builder-modal/form-builder-modal.component';
import { RequestFormModalComponent } from '../../components/modal-templates/widget-creation-modal/request-form-modal/request-form-modal.component';
import { RequestWidgetIntegrationComponent } from '../../components/modal-templates/widget-creation-modal/request-widget-integration/request-widget-integration.component';
import { RequestWidgetTemplateModalComponent } from '../../components/modal-templates/widget-creation-modal/request-widget-template-modal/request-widget-template-modal.component';
import { ServiceNowValueMapModalComponent } from '../../components/modal-templates/widget-creation-modal/service-now-value-map-modal/service-now-value-map-modal.component';
import { CreateWidgetDocumentationStepOneModalComponent } from '../../components/modal-templates/widget-documentation-modal/create-widget-documentation-step-one-modal/create-widget-documentation-step-one-modal.component';
import { CreateWidgetDocumentationStepTwoModalComponent } from '../../components/modal-templates/widget-documentation-modal/create-widget-documentation-step-two-modal/create-widget-documentation-step-two-modal.component';
import { WidgetDocumentationInfoModalComponent } from '../../components/modal-templates/widget-documentation-modal/widget-documentation-info-modal/widget-documentation-info-modal.component';
import { WidgetDocumentationMarkdownGuideModalComponent } from '../../components/modal-templates/widget-documentation-modal/widget-documentation-markdown-guide-modal/widget-documentation-markdown-guide-modal.component';
import { WidgetDocumentationMoreInfoModalComponent } from '../../components/modal-templates/widget-documentation-modal/widget-documentation-more-info-modal/widget-documentation-more-info-modal.component';
import { DescriptionPreviewComponent } from '../../components/modal-templates/widget-modals/description-preview/description-preview.component';
import { BudgetInfoBottomSheetComponent } from '../../components/widgets/1.0/budget-listing/budget-listing.component';
// import { IssueAssessmentStepTwoComponent } from '../../components/widgets/1.0/issue-assessment-step-two/issue-assessment-step-two.component';
import { CommonPipesModule } from '../common-pipes/common-pipes.module';
import { FormModule } from '../form/form.module';
import { HelperComponentModule } from '../helper-component/helper-component.module';
import { MaterialModule } from '../material/material-module.module';
import { UtilsModule } from '../utils/utils.module';
import { KeyValuePairV2Component } from './../../components/key-value-pair-V2/key-value-pair-v2.component';
import { SalesforceIntegrationCaseFormComponent } from './../../components/modal-templates/integration/salesforce-modal/salesforce-integration-case-form/salesforce-integration-case-form.component';
import { SalesforceIntegrationStepFourComponent } from './../../components/modal-templates/integration/salesforce-modal/salesforce-integration-step-four/salesforce-integration-step-four.component';
import { SalesforceIntegrationsStepThreeComponent } from './../../components/modal-templates/integration/salesforce-modal/salesforce-integrations-step-three/salesforce-integrations-step-three.component';
import { RightsizingV2MoreInfoModalComponent } from './../../components/modal-templates/rightsizing-v2/rightsizing-v2-more-info-modal/rightsizing-v2-more-info-modal.component';
import { RightsizingMoreInfoModalV3Component } from './../../components/modal-templates/rightsizing-v3/rightsizing-more-info-modal-v3/rightsizing-more-info-modal-v3.component';
import { GraphModule } from './../graph/graph.module';
import { CreateEmailTemplateComponent } from '../../components/modal-templates/create-email-template/create-email-template.component';
import { EmailConfigurationModalComponent } from '../../components/modal-templates/email-configuration-modal/email-configuration-modal.component';
import { ScoringAssessmentDiscreetOptionsModalComponent } from '../../components/modal-templates/scoring-assessment-discreet-options-modal/scoring-assessment-discreet-options-modal.component';
import { AssessmentGoogleMspFormsComponent } from '../../components/modal-templates/widget-creation-modal/request-form-modal/components/assessment-google-msp-forms/assessment-google-msp-forms.component';
import { ServicenowIncidentsFormGeneratorModalComponent } from '../../components/modal-templates/servicenow-incidents-form-generator-modal/servicenow-incidents-form-generator-modal.component';
import { ReportShareTableComponent } from '../../components/report-share/report-share-table/report-share-table.component';

const components = [
    ConventionsModalComponent,
    AddResourceTagsComponent,
    DeleteResourceTagsComponent,
    DeleteComponent,
    RightsizingModalComponent,
    ExpandableTableMoreInfoModalComponent,
    CreateReportFirstStepComponent,
    CreateReportSecondStepComponent,
    CreateReportThirdStepComponent,
    AddTagConfigFirstStepComponent,
    KeyValuePairComponent,
    CreateCustomerStepOneComponent,
    CreateCustomerStepTwoComponent,
    CreateCustomerStepThreeComponent,
    ConfirmationModalComponent,
    ScoringAssessmentModalComponent,
    ScoringAssessmentDiscreetOptionsModalComponent,
    AutomationModalFiltersComponent,
    AutomationModalSchedulerComponent,
    AutomationModalConfigureNotificationComponent,
    CreateUserStepOneComponent,
    BudgetingModalStep1Component,
    BudgetingModalStep2Component,
    BudgetingModalStep3Component,
    InfoModalComponent,
    EmailTestingModalComponent,
    SlackTestingModalComponent,
    IframeOpenerModalComponent,
    FormGeneratorModalComponent,
    ServicenowIncidentsFormGeneratorModalComponent,
    ServicenowTestingModalComponent,
    CspOnboardingModalComponent,
    EaOnboardingModalComponent,
    PlanOnboardingModalComponent,
    AwsOnboardingModalComponent,
    RiRecommendationModalComponent,
    RiPurchaseFormComponent,
    SavingsPlanRecommendationModalComponent,
    RightsizingModalTableComponent,
    AttentionRequiredAccordionComponent,
    MoreInfoModalComponent,
    AdvancedAutomationModalFiltersComponent,
    AdvancedAutomationModalCongifurationComponent,
    AdvancedAutomationModalSchedulerComponent,
    BudgetInfoBottomSheetComponent,
    CreateWidgetStepOneComponent,
    CreateWidgetStepTwoComponent,
    CreateMgReportStepOneComponent,
    CreateMgReportStepTwoComponent,
    CreateMgReportStepThreeComponent,
    DescriptionPreviewComponent,
    CreateUserStepTwoComponent,
    KeyValuePairV2Component,
    SnsModalComponent,
    AutotaskParentIntegrationStepOneComponent,
    AutotaskParentIntegrationStepTwoComponent,
    AutotaskChildIntegrationComponent,
    AutotaskIntegrationsTableComponent,
    AutotaskCreateTicketModalComponent,
    AddResourceTagsV2Component,
    DeleteResourceTagsV2Component,
    OtrsParentIntegrationStepOneComponent,
    OtrsParentIntegrationStepTwoComponent,
    OtrsChildIntegrationComponent,
    OtrsCreateTicketModalComponent,
    OtrsIntegrationsTableComponent,
    AzureRiRecommendationModalComponent,
    CaIntegrationStepOneComponent,
    CaIntegrationStepTwoComponent,
    CaChildIntegrationComponent,
    CaIntegrationTableComponent,
    CaCreateTicketModalComponent,
    GcpOnboardingModalComponent,
    MasterLoginUsersListComponent,
    MyPriceConfigurationModalComponent,
    CreateViewComponent,
    EditModuleIconComponent,
    EditViewDataComponent,
    ModuleWidgetAdditionComponent,
    LandingPageSelectionComponent,
    ComplianceInfoModalComponent,
    CustomWhitelistingModalComponent,
    ComplianceSeverityInfoModalComponent,
    CustomizationModalComponent,
    RemediateDocumentationModalComponent,
    CreateTagConfigurationFormStepOne,
    CreateTagConfigurationFormStepTwo,
    CustomComplianceWidgetListComponent,
    CreateCustomComplianceStepOneComponent,
    CreateCustomComplianceStepThreeComponent,
    CartComponent,
    ConventionsModalV2Component,
    OtpVerificationModalComponent,
    RightsizingV2MoreInfoModalComponent,
    ConfigureMfaComponent,
    ServicenowTicketInformationComponent,
    UploadJsonComponent,
    WidgetCatalogTagsModalComponent,
    AddWidgetComponent,
    CreateAssessmentComponent,
    IssueAssessmentComponent,
    AssociatedUsersComponent,
    AclModalStepOneComponent,
    AclModalStepTwoComponent,
    AclAssociatedListingComponent,
    CpuUtilizationEditModalComponent,
    CpuUtilizationModalComponent,
    TableGeneratorModalComponent,
    NagiosIntegrationStepOneComponent,
    NagiosGroupEditModalComponent,
    NagiosIntegrationStepTwoComponent,
    AzureRiRecommendationModalV2Component,
    ImageUploadModalComponent,
    WidgetCatalogMoreInfoModalComponent,
    SavingsPlanRecommendationModalV2Component,
    ZendeskCreateTicketComponent,
    AssignAssessmentComponent,
    AzureSponsorshipOnboardingModalComponent,
    AddAzureSponsorshipAccountComponent,
    AzureSponsorshipOnboardingImpactModalComponent,
    McaOnboardingModalComponent,
    McaOnboardingBulkUploadComponent,
    CommonMoreInfoModalComponent,
    RequestDetailsModalComponent,
    GetFullAccessComponent,
    RequestWidgetTemplateModalComponent,
    FormBuilderModalComponent,
    RequestWidgetIntegrationComponent,
    RequestFormModalComponent,
    AssessmentGoogleMspFormsComponent,
    ServiceNowValueMapModalComponent,
    CreateWidgetDocumentationStepOneModalComponent,
    CreateWidgetDocumentationStepTwoModalComponent,
    WidgetDocumentationMarkdownGuideModalComponent,
    WidgetDocumentationInfoModalComponent,
    WidgetDocumentationMoreInfoModalComponent,
    UploadTfFilesModalComponent,
    PreviewTfFileComponent,
    TerraformRequestFormModalComponent,
    TerraformMapVariablesModalComponent,
    TerraformRequestWidgetModalComponent,
    TerraformRequestWidgetFormModalComponent,
    TerraformMoreInfoModalComponent,
    TerraformPreviewResourcesModalComponent,
    CustomerInfoModalComponent,
    AgGridCellColumnComponent,
    CreateCustomerStepOneV2Component,
    SalesforceIntegrationStepTwoComponent,
    SalesforceIntegrationsTableComponent,
    SalesforceIntegrationStepOneComponent,
    SalesforceIntegrationsStepThreeComponent,
    SalesforceIntegrationStepFourComponent,
    SalesforceIntegrationCaseFormComponent,
    ChecksV2MoreInfoModalComponent,
    BulkUploadAzurePlanAccountComponent,
    RiUtilizationModalV2Component,
    RightsizingMoreInfoModalV3Component,
    // IssueAssessmentStepTwoComponent,
    AssessmentMoreInfoModalComponent,
    UploadEvidenceModalComponent,
    AssessmentCreateUserStepOneComponent,
    AssessmentCreateUserStepTwoComponent,
    AssociatedAssessmentsComponent,
    CreateDuplicateViewComponent,
    GroupWidgetModalComponent,
    CreateEmailTemplateComponent,
    EmailConfigurationModalComponent,
    BulkUploadAzureEaComponent,
    ReportShareTableComponent
];
const modules = [
    CommonModule,
    UtilsModule,
    MaterialModule,
    FormsModule,
    NgxSummernoteModule,
    AgGridModule,
    CommonPipesModule,
    FormModule,
    HelperComponentModule,
    GraphModule,
    NgSelectModule,
];
@NgModule({
    declarations: [...components],
    imports: [...modules],
    entryComponents: [...components],
})
export class ModalTemplatesModule {}
