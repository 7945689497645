import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule , CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MaterialCssVarsModule } from 'angular-material-css-vars';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DynamicContentComponent } from './core/components/dynamic-content/dynamic-content.component';
import { LayoutsModule } from './core/modules/layouts/layouts.module';
import { GlobalErrorHandler } from './shared/classes/GlobalErrorHandler';
import { CommonPipesModule } from './shared/modules/common-pipes/common-pipes.module';
import { FormModule } from './shared/modules/form/form.module';
import { GraphModule } from './shared/modules/graph/graph.module';
import { HelperComponentModule } from './shared/modules/helper-component/helper-component.module';
import { MaterialModule } from './shared/modules/material/material-module.module';
import { SharedWidgetsModule } from './shared/modules/shared-widgets/shared-widgets.module';
import { UtilsModule } from './shared/modules/utils/utils.module';
import { AuthenticationService } from './shared/services/authentication/authentication.service';
import { ConfigCacheService } from './shared/services/cache/config-cache/config-cache.service';
import { CredentialsCacheService } from './shared/services/cache/credentials-cache/credentials-cache.service';
import { FilterCacheService } from './shared/services/cache/filters-cache/filter-cache.service';
import { ErrorHandlerService } from './shared/services/error-handler/error-handler.service';
import { FiltersService } from './shared/services/filters/filters.service';
import { GlobalDataService } from './shared/services/global-data/global-data.service';
import { HttpService } from './shared/services/http/http-main/http.service';
import { ListHttpService } from './shared/services/http/list-http/list-http.service';
import { ViewHttpService } from './shared/services/http/view-http/view-http.service';
import { WidgetHttpService } from './shared/services/http/widget-http/widget-http.service';
import { ModalService } from './shared/services/modal/modal-service/modal.service';
import { MultiStepFormService } from './shared/services/modal/multi-step-form/multi-step-form.service';
import { SessionService } from './shared/services/session/session.service';
import { UserDataCacheService } from './shared/services/user-data-cache/user-data-cache.service';
import { WhiteLabelingService } from './shared/services/white-labeling/white-labeling.service';
import "indreka-checkbox";
import { MulticurrencyFilterSelectorService } from './shared/services/multicurrency-filter-selector/multicurrency-filter-selector.service';

// ROUTES CONFIGURATION
const routes = [
    { path: '**', component: DynamicContentComponent, pathMatch: 'full' }
];

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
        AppRoutingModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production
        }),
        BrowserAnimationsModule,
        LayoutsModule,
        HttpClientModule,
        MaterialCssVarsModule.forRoot({
            // all optional
            isAutoContrast: true
        }),
        SharedWidgetsModule,
        MaterialModule,
        UtilsModule,
        FormModule,
        CommonPipesModule,
        HelperComponentModule,
        GraphModule
    ],
    providers: [
        ConfigCacheService,
        AuthenticationService,
        GlobalDataService,
        HttpService,
        ErrorHandlerService,
        WhiteLabelingService,
        FiltersService,
        FilterCacheService,
        ListHttpService,
        SessionService,
        UserDataCacheService,
        WidgetHttpService,
        ModalService,
        MultiStepFormService,
        ViewHttpService,
        CredentialsCacheService,
        Title,
        MulticurrencyFilterSelectorService,

        // Global Error handling Provider
        { provide: ErrorHandler, useClass: GlobalErrorHandler }
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
