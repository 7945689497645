import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { GlobalConfiguration } from 'src/app/core/classes/GlobalConfiguration';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { SubWidgetInjectedData } from 'src/app/shared/classes/SubWidgetInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { GlobalDataService } from 'src/app/shared/services/global-data/global-data.service';
import { DefaultValues } from './../../../../../classes/DefaultValues';
import { HitApi } from './../../../../../classes/HitApi';
import { IconType } from './../../../../../enums/IconType';
import { IIcon } from './../../../../../interfaces/icon-data/IIcon';

@Component({
    selector: 'app-whitelabeling-email',
    templateUrl: './whitelabeling-email.component.html',
    styleUrls: ['./whitelabeling-email.component.sass']
})
export class WhitelabelingEmailComponent implements OnInit, OnDestroy {
    resetObservable: Subject<string>;
    resetSubscription: Subscription;
    subWidgetId: string;
    formGenInput: IFormGeneratorInput;
    dropdownValue = 'new users';
    mapMailTemplate = {
        'new users': 'NEW_USER_EMAIL',
        'new customers': 'NEW_CUSTOMER_EMAIL',
        reports: 'NEW_REPORT_EMAIL',
        alerts: 'NEW_ALERT_EMAIL',
        budget: 'NEW_BUDGET_EMAIL',
        automation: 'NEW_AUTOMATION_EMAIL',
        'reset password': 'RESET_PASSWORD_EMAIL',
        'forget password': 'FORGET_PASSWORD_EMAIL'
    };
    dropdownList = {
        'new users': 'User Introduction Email',
        'new customers': 'Customer Welcome Email',
        reports: 'Attached Reports Email',
        alerts: 'Alert Email',
        budget: 'Budget Email',
        automation: 'Automation Email',
        'reset password': 'Reset Password Email',
        'forget password': 'New Password Confirmation Email'
    };

    mailTemplateInput = {
        'new users': 'user',
        'new customers': 'customer',
        reports: 'report',
        alerts: 'alert',
        budget: 'budget',
        automation: 'automation',
        'reset password': 'resetPassword',
        'forget password': 'forgetPassword'
    };
    tempMail;
    defaultDropdownMailTemplate = Object.keys(this.mapMailTemplate)[0];
    iconData: IIcon = {
        type: IconType.MATICON,
        class: 'info'
    };
    formGroupRef: FormGroup;
    reInitializeWhiteLabelData: Function;

    constructor(
        private subWidgetData: SubWidgetInjectedData,
        public globalDataService: GlobalDataService
    ) {
        this.subWidgetId = subWidgetData.subWidgetId;
        this.resetObservable = subWidgetData.resetObserver;
        this.reInitializeWhiteLabelData =
            subWidgetData.data.reInitializeWhiteLabelData;
    }

    ngOnInit(): void {
        this.initResetObservable();
        this.initFormGenInput();
    }

    initResetObservable() {
        if (this.resetObservable) {
            this.resetSubscription = this.resetObservable.subscribe(
                (targetId) => {
                    if (targetId === this.subWidgetId) {
                        this.initFormGenInput(this.dropdownValue);
                    }
                }
            );
        }
    }

    initFormGenInput(formReset?) {
        let subject, content;
        this.formGenInput = null;
        this.subWidgetData.parentWidgetRef.changeDetectorRef.detectChanges();

        const emailConfigData =
            this.subWidgetData.data.whiteLabelingDraft.emailConfig;
        if (
            this.subWidgetData.data.whiteLabelingData &&
            this.subWidgetData.data.whiteLabelingData.emailConfig
        ) {
            const data = Helper.cloneDeep(
                this.subWidgetData.data.whiteLabelingData.emailConfig
            );
            Object.keys(data).forEach((key) => {
                emailConfigData[key] = data[key];
            });

            this.subWidgetData.data.whiteLabelingDraft.emailConfig =
                emailConfigData;
            this.tempMail = Helper.cloneDeep(emailConfigData);
        } else {
            this.tempMail = Helper.cloneDeep(
                this.subWidgetData.data.whiteLabelingDraft.emailConfig
            );
        }
        if (formReset) {
            subject = this.subWidgetData.data.whiteLabelingDraft.emailConfig[
                this.mailTemplateInput[formReset] + 'Subject'
            ]
                ? this.subWidgetData.data.whiteLabelingDraft.emailConfig[
                      this.mailTemplateInput[formReset] + 'Subject'
                  ]
                : DefaultValues.EMAIL_DATA[this.mapMailTemplate[formReset]]
                      .SUBJECT;
            content = this.subWidgetData.data.whiteLabelingDraft.emailConfig[
                this.mailTemplateInput[formReset] + 'Content'
            ]
                ? this.subWidgetData.data.whiteLabelingDraft.emailConfig[
                      this.mailTemplateInput[formReset] + 'Content'
                  ]
                : DefaultValues.EMAIL_DATA[this.mapMailTemplate[formReset]]
                      .CONTENT;
        }

        this.formGenInput = {
            formName: 'Configure Email Content',
            state: FormState.CREATE,
            submitButton: {
                buttonName: 'Save As Draft',
                buttonType: ButtonType.FLAT,
                buttonColorType: ButtonColorType.PRIMARY,
                showLoader: true,
                disable: GlobalConfiguration.PREVIEW_MODE,
                hoverText: GlobalConfiguration.PREVIEW_MODE
                    ? 'Cannot perform this action in preview mode'
                    : null,
                function: (buttonRef) => {
                    this.saveConfiguration(buttonRef);
                }
            },
            fields: [
                {
                    label: 'Subject',
                    name: 'SUBJECT',
                    fieldType: FilterType.TEXT,
                    placeholder: 'Subject',
                    extraClass: 'subject-container',
                    required: true,
                    value: formReset
                        ? subject
                        : this.subWidgetData.data.whiteLabelingData &&
                          this.subWidgetData.data.whiteLabelingData
                              .emailConfig &&
                          this.subWidgetData.data.whiteLabelingData.emailConfig
                              .userSubject
                        ? this.subWidgetData.data.whiteLabelingData.emailConfig
                              .userSubject
                        : DefaultValues.EMAIL_DATA.NEW_USER_EMAIL.SUBJECT,
                    validations: [
                        {
                            errorMessage: 'Subject is required',
                            validator: CustomValidators.required
                        }
                    ]
                },
                {
                    label: 'Content',
                    name: 'CONTENT',
                    fieldType: FilterType.WYSIWYG_EDITOR,
                    placeholder: 'Content',
                    required: true,
                    value: formReset
                        ? content
                        : this.subWidgetData.data.whiteLabelingData &&
                          this.subWidgetData.data.whiteLabelingData
                              .emailConfig &&
                          this.subWidgetData.data.whiteLabelingData.emailConfig
                              .userContent
                        ? this.subWidgetData.data.whiteLabelingData.emailConfig
                              .userContent
                        : DefaultValues.EMAIL_DATA.NEW_USER_EMAIL.CONTENT,
                    validations: [
                        {
                            errorMessage: 'Content is required',
                            validator: CustomValidators.required
                        }
                    ]
                }
            ]
        };
    }

    setValues(value) {
        this.formGroupRef.reset();
        this.dropdownValue = value;
        value = this.mapMailTemplate[value];
        if (this.dropdownValue && this.mailTemplateInput[this.dropdownValue]) {
            this.formGroupRef
                .get('SUBJECT')
                .setValue(
                    this.tempMail[
                        `${this.mailTemplateInput[this.dropdownValue]}Subject`
                    ]
                        ? this.tempMail[
                              `${
                                  this.mailTemplateInput[this.dropdownValue]
                              }Subject`
                          ]
                        : DefaultValues.EMAIL_DATA[value].SUBJECT
                );
            this.formGroupRef
                .get('CONTENT')
                .setValue(
                    this.tempMail[
                        `${this.mailTemplateInput[this.dropdownValue]}Content`
                    ]
                        ? this.tempMail[
                              `${
                                  this.mailTemplateInput[this.dropdownValue]
                              }Content`
                          ]
                        : DefaultValues.EMAIL_DATA[value].CONTENT
                );
        }
    }

    setEmail(event) {
        if (this.dropdownValue && this.mailTemplateInput[this.dropdownValue]) {
            if (event.value.SUBJECT) {
                this.tempMail[
                    `${this.mailTemplateInput[this.dropdownValue]}Subject`
                ] = event.value.SUBJECT;
            }
            if (event.value.CONTENT) {
                this.tempMail[
                    `${this.mailTemplateInput[this.dropdownValue]}Content`
                ] = event.value.CONTENT;
            }
        }
    }

    saveConfiguration(buttonRef) {
        if (this.subWidgetData.data.saveAsDraftApiInfo) {
            buttonRef.loader = true;
            const emailDraft = Helper.cloneDeep(
                this.subWidgetData.data.whiteLabelingDraft
            );
            emailDraft['emailConfig'] = Helper.cloneDeep(this.tempMail);

            const apiConfig = Helper.generateHitApiConfig(
                this.subWidgetData.data.saveAsDraftApiInfo
            );
            apiConfig.input = emailDraft;

            apiConfig.function = (response) => {
                buttonRef.loader = false;
                this.subWidgetData.data.whiteLabelingData.emailConfig =
                    Helper.cloneDeep(this.tempMail);
                this.subWidgetData.parentWidgetRef.notificationsService.showSnackBar(
                    'Configuration saved successfully. Click on publish to deploy these changes.',
                    false,
                    '',
                    { duration: 3500 }
                );
                if (this.reInitializeWhiteLabelData) {
                    this.reInitializeWhiteLabelData();
                }
                this.initFormGenInput(this.dropdownValue);
                this.subWidgetData.parentWidgetRef.changeDetectorRef.detectChanges();
            };
            apiConfig.errorFunction = (error) => {
                buttonRef.loader = false;
                this.subWidgetData.parentWidgetRef.changeDetectorRef.detectChanges();
                Helper.showErrorMessage(
                    this.subWidgetData.parentWidgetRef.notificationsService,
                    error,
                    'Error in saving Email Configuration'
                );
            };
            new HitApi(
                apiConfig,
                this.subWidgetData.parentWidgetRef.httpService,
                this.subWidgetData.parentWidgetRef.ngZone
            ).hitApi();
        } else {
            buttonRef.loader = false;
        }
    }

    ngOnDestroy(): void {
        if (this.resetSubscription) {
            this.resetSubscription.unsubscribe();
        }
    }
}
