export const environment = {
    production: true,
    envName: 'staging',
    isSentryEnabled: false,
    sentry_dsn: null,
    baseUrl: 'https://apistaging.centilytics.com/v2',
    wsBaseUrl: 'wss://apistaging.centilytics.com/ws',
    mock: false, // set this to true to use mock server to develop a widget
    mockComponent: null // set this to the component to render in case of MOCK_WIDGET PortletType
};
